
const _headers = {
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxOTcxNzUwZi01YmM0LTRlZTQtOTBkYi00YTQwMWI2MjJkN2YiLCJuYmYiOjE2NzE2NjA2NDMsImV4cCI6MTczNDgxOTA0MywiaWF0IjoxNjcxNjYwNjQzLCJpc3MiOiJodHRwczovL3BhY3NvZnQuaW8vIiwiYXVkIjoiaHR0cHM6Ly9wYWNzb2Z0LmlvLyJ9.K6GMOSLDtplVexWlEetkMuE5nbo7ykGQWy4wc9yEGRjkroAohIpSQJmxvbP19pyNMFoogzDgPKrTIJV6DMXdgQ', 
            'Content-Type': 'application/json'
    }

class Api{
    

    Get(paramsId)
    {
        return fetch(`https://kingdomlifestyle-api.pacsoft.io/api/v1/weeks/${paramsId}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxOTcxNzUwZi01YmM0LTRlZTQtOTBkYi00YTQwMWI2MjJkN2YiLCJuYmYiOjE2NzE2NjA2NDMsImV4cCI6MTczNDgxOTA0MywiaWF0IjoxNjcxNjYwNjQzLCJpc3MiOiJodHRwczovL3BhY3NvZnQuaW8vIiwiYXVkIjoiaHR0cHM6Ly9wYWNzb2Z0LmlvLyJ9.K6GMOSLDtplVexWlEetkMuE5nbo7ykGQWy4wc9yEGRjkroAohIpSQJmxvbP19pyNMFoogzDgPKrTIJV6DMXdgQ', 
                        'Content-Type': 'application/json'
                    }
                }
               )
            .then(res =>res.json())
            .then(responseData => 
                {
                    return responseData.data
                }
            ).catch(e =>{
                return undefined
        })

    }


    async Get_data(){
              
        let response = await fetch(`https://kingdomlifestyle-api.pacsoft.io/api/v1/weeks/`,
            {
                method: 'GET',
                headers: _headers
            }
        )

        if(response.ok){
            const data = await response.json();
            return data
        }

       
      
      //  return da
        //     .then(res =>res.json())
        //     .then(responseData => 
        //         {
        //             return responseData.data
        //         }
        //     ).catch(e =>{
        //         console.log(e)
        // })
    }
}


module.exports = new Api() 