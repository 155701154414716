import * as API from '../tools/api'
import {GET_DATA, GET_WEEK_NUMBER, GET_ALL_DATA, GET_WEEK_DATA} from '../actions/week'

var defaultData = await API.Get_data()
.then(x => x)
.then(y => {
   return y.data
})


export function currentWeek(state = null, action){
    
    switch (action.type) {
        case GET_DATA:
            return state = [action.data]

        default:
            return state;
    }
}




export function weeks(state = null, action){
    
    switch (action.type) {
        case GET_ALL_DATA:
            //console.log('state: ', action.data)
            return state = action.data
    
        case GET_WEEK_DATA:
            return  state //&& state.filter(x => x.number == action.weekNumber) 
        
        default:
            return state;
    }
}


export function weekNumber(state = [], action){
    
    switch (action.type) {           
        case GET_WEEK_NUMBER:
            return state =  [...state, action.weekNumber]

        default:
            return state;
    }
}




