import React, {useEffect, useState} from 'react'
import './App.css';
import Home from './components/home'
import Main from './components/main';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { Provider, Provider as StoreProvider } from 'react-redux';
import Store from './store'
import {Handle_Get_Data, Handle_Get_WeekNumber, Handle_Load_Data} from './actions/week'



function App(props)
{
  useEffect(()=>{
    Store.dispatch(Handle_Get_WeekNumber())
    Store.dispatch(Handle_Get_Data(1))
    Store.dispatch(Handle_Load_Data())
  }, [])
   
   return(
    <Provider store={Store}>
    <BrowserRouter>
      <HelmetProvider>
        <Routes>
          <Route path='/' Component={()=><Home />} />
          <Route path='/:id' Component={()=><Main />} />
        </Routes>
        {/* < Home /> */}
      </HelmetProvider>
    </BrowserRouter>
    </Provider>
  )
}

export default App;
