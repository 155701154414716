import React, {useState} from 'react'
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";


function Navbar({weekNumber, handleWeekLink}) {

    const [params, setParams] = useState(useParams())

    const weeks = ()=>{

        let links = []
        for (let index = 0; index < weekNumber[0]; index++) {
            
            links.push(
                    <div key={index + 1}>
                        <button className="dropdown-item btn btn-sm" onClick={()=>{
                            handleWeekLink(index + 1)
                        }}>Week {index + 1}</button>
                        <div className="dropdown-divider"></div>
                    </div>
                )
        }

        return links.map(x => x)
    }

    return (
    <div>

        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href='/'>Kingdom Lifestyle</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    
                    <li className="nav-item active">
                        <a className="nav-link" href="https://crispinkamucici.com/about/" target="_blank">About</a>
                    </li>

                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Weeks
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            {weeks()}
                        </div>
                    </li>

                </ul>
                
            </div>
            </nav>
        
    </div>
  )
}


const mapStateToProps = ({ weekNumber}) => {
  return {
      weekNumber
  };
};

export default connect(mapStateToProps)(Navbar);