import React from 'react'
import WeekTemplate from './weekTemplate'
import { Helmet } from 'react-helmet-async'
import Navbar from './navbar'
import {Handle_Get_Data} from '../../actions/week'
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";



function pageTemplate(props) 
{

    const {dispatch, data, match} = props
    let _id = props
    const handleSetWeekId = (id)=>{
        console.log('id', id)
        dispatch(Handle_Get_Data(id))
        dispatch(Handle_Get_Data(_id))
        
    }  
  
    const {startDate, title, mobileContent, image} = data

    return (
        <div className='page'>
            
            <Helmet>
                <title>Kingdom Lifestyle | {title}</title>

                {/* Open Graph meta tags */}
                <meta property="og:title" content={title} data-rh="true" />
                <meta property="og:description" content={mobileContent[0] + mobileContent[1] + + mobileContent[2] + + mobileContent[3]} data-rh="true" />
                <meta property="og:image" content={image} data-rh="true" />
                <meta property="og:image:width" content="640" data-rh="true" />
                <meta property="og:image:height" content="427" data-rh="true" />
                <meta property="og:type" content="website" data-rh="true" />
                <meta property="og:url" content="https://kingdomlifestyle-share.pacsoft.io/" data-rh="true" />

                {/* Twitter Card meta tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={mobileContent[0] + mobileContent[1] + + mobileContent[2] + + mobileContent[3]} />
                <meta name="twitter:image" content={image} />
                <meta name="twitter:image:alt" content={title}></meta>

            </Helmet>
            
            <div className='container'>

                <Navbar handleWeekLink={handleSetWeekId}  />

                <div className="jumbotron jumbotron-fluid">
                    <img src={image} alt={title} className="feature-image" />
                </div>
            </div>

            <article className='container'>
                
                <header>
                    <h1 className="display-5">{title}</h1>
                </header>

                <div>
                    <div className="meta text-muted">
                        <i> By Crispin Kamucici | Published on: <span>December 31, 2023</span></i>
                            <div className="social-media">
                                <a href="https://x.com/crispinkamucici" target="_blank">
                                    <i className="fab fa-twitter"></i> Twitter
                                </a> | <a href="https://www.facebook.com/kamucici" target="_blank">
                                    <i className="fab fa-facebook"></i> Facebook
                                </a> | <a href="https://www.youtube.com/@crispinkamucici-kingdomlif255" target="_blank">
                                    <i className="fab fa-youtube"></i> YouTube
                                </a>


                            </div>
                    </div>
                </div>
                
               <WeekTemplate wrappedContent={mobileContent} />

            </article>
        </div>
  )
}



const mapStateToProps = ({ currentWeek, weekNumber}) => {
  return {
      currentWeek, 
      weekNumber
  };
};

export default connect(mapStateToProps)(pageTemplate);