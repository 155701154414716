import React from 'react'


const ContentWrapper = (inputArray) => {
  let isInPropheticPrayer = false;
  
  return inputArray
    .filter(item => item.trim() !== "") 
    .map(item => {
      if (item.startsWith("~") && item.endsWith("~")) {
        if (item.slice(1, -1) === "PROPHETIC PRAYER" || item.slice(1, -1) === "PROPHETIC DECLARATION") {
          isInPropheticPrayer = true;
        } else {
          isInPropheticPrayer = false;
        }
        return `<h3>${item.slice(1, -1)}</h3>`; 
      } else if (isInPropheticPrayer) {
        isInPropheticPrayer = false;
        return `<blockquote><p>${item}</p></blockquote>`; 
      } else {
        return `<p>${item}</p>`; 
      }
    })
    .join(""); 
};



export default function WeekTemplate({wrappedContent}) {

  return (
     <div>
        {wrappedContent && <div dangerouslySetInnerHTML={{ __html: ContentWrapper(wrappedContent) }} />}
    </div>
  )
}
