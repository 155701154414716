import React, {useEffect, useState} from 'react'

import { useParams } from "react-router-dom";
import { connect } from 'react-redux';
import Navbar from './shared/navbar'
import PageTemplate from './shared/pageTemplate'
import { weeks } from '../reducers/week';


function Main({currentWeek,weeks, dispatch}) {
    const [paramsId, setParamsId ] = useState(useParams())
    const [apiData, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(()=>{
        if(weeks !== null){
            setIsLoading(false)
            setData(weeks[paramsId.id])
        }
    }, [weeks, paramsId])


    if(isLoading){
       return(<>
            <h1>loading...</h1>
        </>)
    }

    
    return( apiData && <PageTemplate  data={apiData}/> )

}

const mapStateToProps = ({ currentWeek, weekNumber, weeks}) => {
  return {
      currentWeek, 
      weekNumber, 
      weeks
  };
};

export default connect(mapStateToProps)(Main);