

export const GET_WEEK_NUMBER = "GET_WEEK_NUMBER"
export const GET_DATA = "GET_DATA"
export const GET_ALL_DATA = "GET_ALL_DATA"
export const GET_WEEK_DATA = 'GET_WEEK_DATA'

export function Get_Data(data){
   return {
    type : "GET_DATA", 
    data
   }
}

export function Get_WeekNumber(weekNumber){
   return {
    type : "GET_WEEK_NUMBER", 
    weekNumber
   }
}


export function Get_All_Data(data){
   return {
    type : "GET_ALL_DATA", 
    data
   }
}

export function Get_WEEK_Data(weekNumber){
   return {
    type : "GET_WEEK_DATA", 
    weekNumber
   }
}


export function Handle_Get_Data(id){
   
    return (dispatch) => {
        fetch(`https://kingdomlifestyle-api.pacsoft.io/api/v1/weeks/${id}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxOTcxNzUwZi01YmM0LTRlZTQtOTBkYi00YTQwMWI2MjJkN2YiLCJuYmYiOjE2NzE2NjA2NDMsImV4cCI6MTczNDgxOTA0MywiaWF0IjoxNjcxNjYwNjQzLCJpc3MiOiJodHRwczovL3BhY3NvZnQuaW8vIiwiYXVkIjoiaHR0cHM6Ly9wYWNzb2Z0LmlvLyJ9.K6GMOSLDtplVexWlEetkMuE5nbo7ykGQWy4wc9yEGRjkroAohIpSQJmxvbP19pyNMFoogzDgPKrTIJV6DMXdgQ', 
                        'Content-Type': 'application/json'
                    }
                }
                )
            .then(res =>res.json())
            .then(responseData => 
                {
                    dispatch(Get_Data(responseData.data))
                }
            ).catch(e =>{
                console.log(e)
        })
    };
}


export function Handle_Load_Data(){
   
    return (dispatch) => {
        fetch(`https://kingdomlifestyle-api.pacsoft.io/api/v1/weeks/`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxOTcxNzUwZi01YmM0LTRlZTQtOTBkYi00YTQwMWI2MjJkN2YiLCJuYmYiOjE2NzE2NjA2NDMsImV4cCI6MTczNDgxOTA0MywiaWF0IjoxNjcxNjYwNjQzLCJpc3MiOiJodHRwczovL3BhY3NvZnQuaW8vIiwiYXVkIjoiaHR0cHM6Ly9wYWNzb2Z0LmlvLyJ9.K6GMOSLDtplVexWlEetkMuE5nbo7ykGQWy4wc9yEGRjkroAohIpSQJmxvbP19pyNMFoogzDgPKrTIJV6DMXdgQ', 
                        'Content-Type': 'application/json'
                    }
                }
                )
            .then(res =>res.json())
            .then(responseData => 
                {
                    dispatch(Get_All_Data(responseData.data))
                }
            ).catch(e =>{
                console.log(e)
        })
    };
}


export function Handle_Get_WeekNumber(){
   
    return (dispatch) => {
         fetch(`https://kingdomlifestyle-api.pacsoft.io/api/v1/weeks/`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxOTcxNzUwZi01YmM0LTRlZTQtOTBkYi00YTQwMWI2MjJkN2YiLCJuYmYiOjE2NzE2NjA2NDMsImV4cCI6MTczNDgxOTA0MywiaWF0IjoxNjcxNjYwNjQzLCJpc3MiOiJodHRwczovL3BhY3NvZnQuaW8vIiwiYXVkIjoiaHR0cHM6Ly9wYWNzb2Z0LmlvLyJ9.K6GMOSLDtplVexWlEetkMuE5nbo7ykGQWy4wc9yEGRjkroAohIpSQJmxvbP19pyNMFoogzDgPKrTIJV6DMXdgQ', 
                        'Content-Type': 'application/json'
                    }
                }
                )
            .then(res =>res.json())
            .then(responseData => 
                {
                    dispatch(Get_WeekNumber(responseData.currentWeek))
                }
            ).catch(e =>{
                console.log(e)
        })
    };
}