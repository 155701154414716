import React, {useEffect, useState} from 'react'

import { useParams } from "react-router-dom";
import { connect } from 'react-redux';
import Navbar from './shared/navbar'
import PageTemplate from './shared/pageTemplate'


function Home({currentWeek, dispatch}) {
    const [paramsId, setParamsId ] = useState(useParams())
    const [apiData, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(()=>{
        if(currentWeek !== null){
            setIsLoading(false)
            setData(currentWeek[0])
        }
    }, [currentWeek, paramsId])

    if(isLoading){
       return(<>
            <h1>loading...</h1>
        </>)
    }
  
    return( apiData && <PageTemplate  data={apiData}/> )

}

const mapStateToProps = ({ currentWeek, weekNumber}) => {
  return {
      currentWeek, 
      weekNumber
  };
};

export default connect(mapStateToProps)(Home);